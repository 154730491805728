import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import LandingPageHero from "../components/LandingPageHero";

// eslint-disable-next-line
export const IndexPageTemplate = () => (
  <div>
    <LandingPageHero />

    <div className="index-menu-container">
      <Link className="index-menu-link" to="/projects">
        <h1>Projects</h1>
      </Link>
      <Link className="index-menu-link" to="/about">
        <h1>About</h1>
      </Link>
      <Link className="index-menu-link" to="/contact">
        <h1>Contact</h1>
      </Link>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {};

const IndexPage = () => (
  <Layout>
    <IndexPageTemplate />
  </Layout>
);

IndexPage.propTypes = {};

export default IndexPage;
