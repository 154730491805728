import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import logoGif from "../img/icons/logo.gif";

export default function LandingPageHero() {
  return (
    <div className="hero-container">
      <div className="item-a">
        <StaticImage
          src="../img/icons/bottom_right_triangle.jpg"
          alt="Triangle"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-b">
        <StaticImage
          src="../img/icons/logo.jpg"
          alt="Nina Tomczyk"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-c">
        <StaticImage
          src="../img/icons/x_with_sides.jpg"
          alt="X"
          imgStyle={{ objectFit: "contain" }}
        />

        <div style={{ width: "30px" }} />

        <StaticImage
          className="full-image"
          src="../img/icons/full.jpg"
          alt="Full"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-d">
        <StaticImage
          src="../img/icons/m_left.jpg"
          alt="X"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-e">
        <img src={logoGif} alt="I am Nina" style={{ objectFit: "contain" }} />
      </div>

      <div className="item-f">
        <StaticImage
          src="../img/icons/top_left_arrow.jpg"
          alt="Arrow"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-g">
        <StaticImage
          src="../img/icons/full.jpg"
          alt="Full"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-h">
        <StaticImage
          src="../img/icons/bottom_left_arrow.jpg"
          alt="Arrow"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-i">
        <StaticImage
          src="../img/icons/x.jpg"
          alt="X"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-j">
        <StaticImage
          src="../img/icons/plus.jpg"
          alt="Plus"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-k">
        <StaticImage
          src="../img/icons/top_left_triangle.jpg"
          alt="Arrow"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-l">
        <StaticImage
          src="../img/icons/x_with_sides.jpg"
          alt="X"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-m">
        <StaticImage
          src="../img/icons/top_left_arrow.jpg"
          alt="Arrow"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-n">
        <StaticImage
          src="../img/icons/three_lines.jpg"
          alt="Three lines"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-o">
        <StaticImage
          src="../img/icons/three_lines.jpg"
          alt="Three lines"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-p">
        <StaticImage
          src="../img/icons/x_with_top_bottom.jpg"
          alt="Three lines"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>

      <div className="item-q">
        <StaticImage
          src="../img/icons/parenthesis.jpg"
          alt="Three lines"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </div>
  );
}

LandingPageHero.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
